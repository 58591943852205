const ConfigFile = require('./config.json');

const config = {
    // Azure Configuration
    appId: process.env.AZURE_APP_ID || ConfigFile.azureConfig.APP_ID,
    redirectUrl: process.env.AZURE_REDIRECT_URL || ConfigFile.azureConfig.REDIRECT_URL,
    scopes: [ "User.Read" ],
    authority: process.env.AZURE_APP_AUTHORITY || ConfigFile.azureConfig.APP_AUTHORITY,
    showLoginForm: false,

    // BOPIS service configuration
    baseUrl : process.env.BOPIS_BASE_URL || ConfigFile.baseUrl,
    orgId : process.env.BOPIS_ORG_ID || ConfigFile.orgId,
    apiKey: process.env.BOPIS_API_KEY || ConfigFile.apiKey,
    logo : process.env.BOPIS_LOGO || ConfigFile.logo,
    timeout : process.env.TIMEOUT || ConfigFile.timeout,

}

export default config;