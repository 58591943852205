import React from 'react';
import moment from 'moment';
import { withStyles } from "@mui/styles";
import { withRouter } from 'react-router-dom';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import CardContent from '@mui/material/CardContent';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

import { baseStore as BaseStore } from '../../services/BaseStore';
import CustomTag from '../common/CustomTag';
import CustomFooter from '../common/CustomFooter';
import CustomButton from '../common/CustomButton';
import { formatPhoneNumber, enumOrderStatus } from '../../helpers';

const styles = theme => ({
    searchBar: {
        [theme.breakpoints.down("sm")]: {
            display: "none"
        },
    }
});

class Orders extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedOrder: null,
            filter: [enumOrderStatus.pickRequired, enumOrderStatus.inProgress],
            loading: false,
            toPick: 0,
            staged: 0,
            openDialog: false,
            orders: [],
            items: JSON.parse(localStorage.getItem('userItems')),
            defaultLocation: JSON.parse(localStorage.getItem('defaultLocation')),
        };
    }

    handleChange = (event) => {
        this.setState({ search: event.target.value });
    }

    handleClose = () => {
        this.setState({ openDialog: false });
    }

    handleSelectOrders = () => {
        const { orderNumber } = this.state.selectedOrder;
        this.props.history.push(`/orders/staged/${orderNumber}`);
    }

    handleOrderClick = (order) => {
        let { items } = this.state;
        const checkItem = items.find(item => item.id === order.id);

        if (checkItem) {
            items = items.filter(item => item.id !== order.id);
        } else {
            items = [...items, order];
        }

        this.setState({ items });
    }

    handleStartPick = () => {
        localStorage.setItem('userItems', JSON.stringify(this.state.items));
        this.props.history.push(`/orders/complete-pick`);
    }

    renderFooter = () => {
        return (
            <CustomFooter>
                <CustomButton
                    variant='contained'
                    color='primary'
                    onClick={() => this.handleStartPick()}
                    disabled={this.state.items.length === 0}
                >
                    Start Pick
                </CustomButton>
            </CustomFooter>
        );
    }

    renderOrders = () => {
        let content = [];
        let newOrders = this.state.orders;
        const { loading, items } = this.state;

        if (loading) {
            return (
                <Grid item xs={12}>
                    <Paper elevation={2}>
                        <Grid container>
                            <Grid item xs={12}>
                                <CardContent className='flex items-center justify-center card-content' style={{ height: '116px' }}>
                                    <CircularProgress />
                                </CardContent>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            );
        }

        newOrders.forEach(order => {
            const { id, orderNumber, customerInfo, orderCreatedTs, orderLineItems } = order;
            const itemCheck = items.find(item => item.id === id);

            content.push(
                <Grid className='pointer-cursor' item xs={12} key={id} onClick={() => this.handleOrderClick(order)}>
                    <Paper elevation={2} className='card-content'>
                        <Grid container >
                            <Grid className='flex items-center justify-center' item xs={1}>
                                {
                                    itemCheck ?
                                        <CheckCircleIcon className='text-green text-28px' /> :
                                        <RadioButtonUncheckedIcon className='text-gray text-28px' />
                                }
                            </Grid>
                            <Grid item xs={6}>
                                <CardContent className='p-0'>
                                    <Typography className='text-18px text-bold pb-10px' color="primary">
                                        {orderNumber}
                                    </Typography>
                                    <Typography className='text-capitalize' variant="body2" color="black">
                                        Customer: {customerInfo.name}
                                    </Typography>
                                    <Typography variant="body2" color="black">
                                        Date Placed: {moment(orderCreatedTs).format('YYYY-MM-DD hh:mm')}
                                    </Typography>
                                </CardContent>
                            </Grid>
                            <Grid item xs={5}>
                                <CardContent className='p-0 text-right'>
                                    <div className='flex justify-end pb-10px'>
                                        <CustomTag order={order} />
                                    </div>
                                    <Typography variant="body2" color="black">
                                        Number of Items: {orderLineItems.length}
                                    </Typography>
                                    <Typography variant="body2" color="black">
                                        Phone: {formatPhoneNumber(customerInfo.phone)}
                                    </Typography>
                                </CardContent>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            );
        });

        if (content.length === 0) {
            return (
                <Grid item xs={12}>
                    <Paper elevation={2}>
                        <Grid container>
                            <Grid item xs={12}>
                                <CardContent className='card-content'>
                                    <Typography className='text-20px text-semibold' color="primary" gutterBottom>
                                        No results found.
                                    </Typography>
                                </CardContent>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            );
        }

        return content;
    }

    getOrders = () => {
        const { filter, defaultLocation } = this.state;
        let filters = {
            filter: [
                {
                    "field": "orderStatus",
                    "operator": "in",
                    "value": filter
                },
                {
                    "field": "siteId",
                    "operator": "==",
                    "value": defaultLocation?.id,
                }
            ]
        }

        return BaseStore.httpClient.post('/api/query', filters)
            .then(result => {
                const orders = result.data.sort((a, b) => new Date(a.orderCreatedTs) - new Date(b.orderCreatedTs));

                if (filter.includes(enumOrderStatus.pickRequired) || filter.includes(enumOrderStatus.inProgress)) {
                    this.setState({ toPick: orders.length });
                } else if (filter.includes(enumOrderStatus.staged) || filter.includes(enumOrderStatus.toRelease)) {
                    this.setState({ staged: orders.length });
                }

                this.setState({ orders: orders, loading: false });
                return result;
            })
            .catch(err => console.log('handleLogin Error', err));
    }

    newRequest = (types) => {
        this.setState({ filter: types, loading: true, search: '' }, () => {
            this.getOrders();
        });
    }

    UNSAFE_componentWillMount() {
        this.newRequest(this.state.filter);
    }

    render() {
        return (
            <React.Fragment>
                <AppBar
                    position="static"
                    color="default"
                    elevation={0}
                    sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
                >
                    <Container style={{ maxWidth: '1000px' }}>
                        <Toolbar sx={{ flexWrap: 'wrap' }}>
                            <div className='text-16px text-semibold back pointer-cursor' onClick={() => this.props.history.push(`/orders`)}>
                                <IconButton className='back-button' size="small">
                                    <ChevronLeftIcon />
                                </IconButton>
                                <Typography variant="p" color="inherit" noWrap sx={{ flexGrow: 1, marginRight: '5px' }}>
                                    Select Orders
                                </Typography>
                            </div>
                        </Toolbar>
                    </Container>
                </AppBar>
                <Container disableGutters maxWidth="lg" component="main" sx={{ pt: 6, pb: 6 }} style={{ maxWidth: '900px' }}>

                    <Grid container spacing={2} sx={{ pb: 6 }}>
                        {this.renderOrders()}
                    </Grid>

                </Container>
                {this.renderFooter()}
            </React.Fragment >
        );
    }
}

export default withRouter(withStyles(styles)(Orders));
