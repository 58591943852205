import React, { useState } from 'react';
import moment from 'moment';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import CardContent from '@mui/material/CardContent';
import CircularProgress from '@mui/material/CircularProgress';
import CustomTag from '../../common/CustomTag';
import CustomButton from '../../common/CustomButton';

import { formatPhoneNumber, convertToPdf, enumOrderStatus, orderFilter } from '../../../helpers';
import { baseStore as BaseStore } from '../../../services/BaseStore';

const OrderPaper = ({ order, handleOrderClick, staged }) => {
    const { id, orderNumber, customerInfo, orderCreatedTs, orderType, orderLineItems, transportAttrs, orderStatus } = order;
    const [enabled, setEnabled] = useState(false);

    const createShippingLabel = () => {
        if (orderNumber) {
            setEnabled(true);
            return BaseStore.httpClient.get(`/oms/api/print/packingSlip/${orderNumber}`)
                .then(result => {
                    setEnabled(false);
                    convertToPdf(result.data, orderNumber);
                })
                .catch(error => {
                    setEnabled(false);
                });
        }
    }

    const clickable = () => {
        if (orderFilter.excempted.includes(orderStatus)) {
            return false;
        }
        return handleOrderClick(order)
    }

    return (
        <Grid
            item
            xs={12}
            key={id}
            className={staged || orderFilter.excempted.includes(orderStatus) ? '' : "pointer-cursor"}
            onClick={() => clickable()}
        >
            <Paper elevation={2} className='card-content'>
                <Grid container>
                    <Grid item xs={6}>
                        <CardContent className='p-0'>
                            <Typography className='text-18px text-bold pb-20px' color="primary">
                                {transportAttrs.orderNumber}
                            </Typography>
                            <Typography className='text-capitalize' variant="body2" color="black" gutterBottom>
                                Customer: {customerInfo.name}
                            </Typography>
                            <Typography variant="body2" color="black" gutterBottom>
                                Phone: {formatPhoneNumber(customerInfo.phone)}
                            </Typography>
                            <Typography variant="body2" color="black" gutterBottom>
                                Street: {customerInfo.streetAddress}
                            </Typography>
                            <Typography variant="body2" color="black" gutterBottom>
                                City/State: {customerInfo.city} {customerInfo.state}
                            </Typography>
                            <Typography variant="body2" color="black" gutterBottom>
                                Zip Code: {customerInfo.zip}
                            </Typography>
                        </CardContent>
                    </Grid>
                    <Grid item xs={6}>
                        <CardContent className='p-0 text-right h-full'>
                            <div className='flex justify-end pb-20px'>
                                <CustomTag order={order} />
                            </div>
                            <Typography variant="body2" color="black" gutterBottom>
                                Number of Items: {orderLineItems.length}
                            </Typography>
                            <Typography variant="body2" color="black" gutterBottom>
                                Date Placed: {moment(orderCreatedTs).format('YYYY-MM-DD hh:mm')}
                            </Typography>
                            <Typography variant="body2" color="black" gutterBottom>
                                Type: {orderType === enumOrderStatus.bopis ? 'Pickup' : 'Ship To Home'}
                            </Typography>
                        </CardContent>
                    </Grid>
                </Grid>
                {
                    staged && (
                        <Grid className='pt-20px'>
                                <CustomButton
                                    variant='contained'
                                    color='primary'
                                    disabled={enabled}
                                    onClick={() => createShippingLabel()}
                                >
                                    Print Packing Slip
                                    {enabled && <CircularProgress sx={{ m: 0.5, ml: 1 }} size={20} />}
                                </CustomButton>
                        </Grid>
                    )
                }
            </Paper>
        </Grid>
    )
}

export default OrderPaper;
