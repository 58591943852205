import React, { Fragment, Component, memo } from 'react';
import { withRouter } from 'react-router-dom';
import isAuthorized from '../utils/isAuthorized';
import { baseStore as BaseStore } from '../services/BaseStore';
import configFile from '../Config.js';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Container from '@mui/material/Container';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';

import CustomButton from './common/CustomButton';

import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig, msalScopes } from '../config/msalConfig';
import WindowIcon from './common/WindowsIcon';

const North40Logo = () => {
    return <img src={configFile.logo} alt="North40" style={{ maxWidth: '375px' }} />
}

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isAuthenticated: false,
            isAuthenticating: false,
        }

        this.publicClientApplication = new PublicClientApplication(msalConfig);
    }

    requestAzure = () => {
        this.setState({ isAuthenticating: true });
        return this.publicClientApplication.handleRedirectPromise()
            .then(() => {
                return this.publicClientApplication.loginPopup(msalScopes);
            })
            .catch(err => {
                console.error(err);
                return err;
            });
    }

    loginWithAzure = async () => {
        return this.requestAzure()
            .then(user => {
                if (!user) {
                    return Promise.reject({
                        message: 'user not found.'
                    });
                }
                if (user && !user.account) {
                    return Promise.reject({
                        message: 'user account not found.'
                    });
                }

                if (user.account) {
                    this.setState({ isAuthenticated: true });
                    localStorage.setItem('user', JSON.stringify(user.account));
                    localStorage.setItem('token', user.accessToken);
                }
                return user;
            }).then(() => {
                return this.getLocations();
            }).then(locations => {
                if (locations && locations.data && locations.data.length > 0) {
                    localStorage.setItem('locations', JSON.stringify(locations.data));
                    if (!localStorage.getItem('defaultLocation')) {
                        localStorage.setItem('defaultLocation', JSON.stringify(locations.data[0]));
                    }
                    return;
                }
                return;
            }).then(() => {
                return this.getMetaData();
            }).then(metaData => {
                if (metaData && metaData.data) {
                    localStorage.setItem('metaData', JSON.stringify(metaData.data.metadata))
                    return;
                }
                return;
            }).then(() => {
                return this.props.history.push('/orders');
            }).catch(error => {
                localStorage.removeItem('user');
                localStorage.removeItem('token');
                localStorage.removeItem('metaData');
                localStorage.removeItem('locations');
                this.setState({
                    isAuthenticated: false,
                    isAuthenticating: false,
                });
                return error;
            });
    }

    handleLogin = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        this.setState({ isAuthenticating: true });

        BaseStore.httpClient.post('/api/login', {}, {
            auth: {
                username: data.get('email'),
                password: data.get('password')
            }
        })
            .then(result => {
                let user = result.data;
                user.email = data.get('email');

                this.setState({ isAuthenticated: true });
                localStorage.setItem('user', JSON.stringify(user));
                localStorage.setItem('token', JSON.stringify(user.token));
                return user;
            }).then(() => {
                return this.getLocations();
            }).then(locations => {
                if (locations && locations.data && locations.data.length > 0) {
                    localStorage.setItem('locations', JSON.stringify(locations.data));
                    if (!localStorage.getItem('defaultLocation')) {
                        localStorage.setItem('defaultLocation', JSON.stringify(locations.data[0]));
                    }
                    return;
                }
                return;
            }).then(() => {
                return this.getMetaData();
            }).then(metaData => {
                if (metaData && metaData.data) {
                    localStorage.setItem('metaData', JSON.stringify(metaData.data.metadata))
                    return;
                }
                return;
            }).then(() => {
                return this.props.history.push('/orders');
            }).catch(error => {
                localStorage.removeItem('user');
                localStorage.removeItem('token');
                localStorage.removeItem('metaData');
                localStorage.removeItem('locations');
                this.setState({
                    isAuthenticated: false,
                    isAuthenticating: false,
                });
                return error;
            });
    }

    getMetaData = () => {
        return BaseStore.httpClient.get('/api/metadata');
    }

    getLocations = () => {
        return BaseStore.httpClient.get('/oms/api/list/locations');
    }

    renderLoginForm = () => {
        const { isAuthenticated, isAuthenticating } = this.state;

        if (!configFile.showLoginForm || (configFile.showLoginForm && isAuthenticated)) {
            return;
        }

        return (
            <Fragment>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    autoFocus
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                />
                <CustomButton
                    type="submit"
                    fullWidth
                    variant='contained'
                    color='primary'
                    sx={{ mt: 3 }}
                    disabled={isAuthenticated || isAuthenticating}
                >
                    Sign In
                </CustomButton>
            </Fragment>
        );
    }

    UNSAFE_componentWillMount() {
        if (isAuthorized()) {
            this.props.history.push('/orders');
            return;
        }
    }

    render() {
        const { isAuthenticated, isAuthenticating } = this.state;

        return (
            <Container component="main" maxWidth="xs" className='flex items-center pt-56px'>
                <Box className='flex items-center flex-column w-full' style={{ marginTop: '16px', }}>
                    <North40Logo />
                    {isAuthenticated && (
                        <Alert sx={{ mt: 2, width: '100%' }} action={<CircularProgress sx={{ m: 0.5 }} size={20} />}>
                            Authentication success.
                        </Alert>
                    )}
                    <Box component="form" onSubmit={(e) => this.handleLogin(e)} noValidate sx={{ mt: 1 }} className='w-full'>
                        {this.renderLoginForm()}
                        <CustomButton
                            type="button"
                            fullWidth
                            variant='contained'
                            color='primary'
                            sx={{ mt: 3 }}
                            onClick={() => this.loginWithAzure()}
                            className='w-full'
                            disabled={isAuthenticated || isAuthenticating}
                            startIcon={<WindowIcon />}
                        >
                            Login with Azure
                        </CustomButton>
                    </Box>
                </Box>
            </Container>
        );
    }
}

export default withRouter(memo(Login));