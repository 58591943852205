import React, { Fragment } from 'react';
import { withStyles } from "@mui/styles";
import { withRouter } from 'react-router-dom';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import CardContent from '@mui/material/CardContent';
import Link from '@mui/material/Link';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import { baseStore as BaseStore } from '../../services/BaseStore';
import CustomFooter from '../common/CustomFooter';
import CustomButton from '../common/CustomButton';
import CustomCard from '../common/CustomCard';
import CustomSelect from '../common/CustomSelect';
import CustomTextfield from '../common/CustomTextfield';
import CustomDialog from '../common/CustomDialog';
import CustomToast from '../common/CustomToast';
import CustomLoadingCard from '../common/CustomLoadingCard';

import OrderPaperImage from './parts/OrderPaperImage';
import OrderPaper from './parts/OrderPaper';
import { formatPhoneNumber, convertToPdf, enumOrderStatus } from '../../helpers';

const styles = theme => ({
    searchBar: {
        [theme.breakpoints.down("sm")]: {
            display: "none"
        },
    }
});

class Staged extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            order: null,
            packageLength: '',
            packageWidth: '',
            packageHeight: '',
            packageWeight: '',
            carrier: null,
            carriers: [],
            service: null,
            services: [],
            rate: null,
            rates: [],
            orderId: this.props.match.params.orderId,
            loadingDialog: false,
            openReleaseDialog: false,
            openCompleteShipDialog: false,
            openSnack: false,
            openRateDialog: false,
            loadingRates: false,
            loadingVoid: false,
            loadingManual: false,
            loadingShippingLabel: false,
            shipments: [],
            selectedShipment: null,
            selectedShipmentIndex: null,
            openDeleteShipDialog: false,
            manualTrackingNumber: null,
            manualCarrier: null,
            openManualDialog: false,
        };
    }

    handleClose = () => {
        this.setState({ openReleaseDialog: false });
    }

    handleCompleteShipClose = () => {
        this.setState({ openCompleteShipDialog: false });
    }

    handleDeleteShipClose = () => {
        this.setState({ openDeleteShipDialog: false, selectedShipment: null, selectedShipmentIndex: null });
    }

    handleRateClose = () => {
        this.setState({ openRateDialog: false });
    }

    handleManualClose = () => {
        this.setState({ openManualDialog: false, manualTrackingNumber: null, manualCarrier: null });
    }

    handleReleaseShipment = () => {
        this.setState({ openReleaseDialog: true });
    }

    handleCompleteShipment = () => {
        this.setState({ openCompleteShipDialog: true });
    }

    handleConfirmRelease = () => {
        const { order, orderId } = this.state;
        let currentOrder = order;
        currentOrder.orderStatus = enumOrderStatus.released;
        this.setState({ loadingDialog: true }, () => {
            return BaseStore.httpClient.put(`/api/orders/${orderId}?trigger=true`, order)
                .then(() => {
                    this.setState({ openSnack: true }, () => {
                        setTimeout(() => {
                            this.props.history.push(`/orders`);
                        }, 2500);
                    });
                })
                .catch(err => {
                    this.setState({ loadingDialog: false, openSnack: false });
                    console.log('Error', err);
                });
        });
    }

    handleConfirmUpdateShipments = (trigger) => {
        const { order, shipments } = this.state;
        let currentOrder = order;
        currentOrder.shipments = shipments;
        if (trigger) {
            currentOrder.orderStatus = enumOrderStatus.released;
            this.setState({ loadingDialog: true });
        }
        return BaseStore.httpClient.put(`/api/orders/${order.id}?trigger=${trigger}`, currentOrder)
            .then(result => {
                if (trigger) {
                    this.setState({ openSnack: true }, () => {
                        setTimeout(() => {
                            this.props.history.push(`/orders`);
                        }, 2500);
                    });
                }
                const shipments = result.data?.shipments ? result.data.shipments : [];
                this.setState({ shipments, order: result.data });
                return Promise.resolve();
            })
            .catch(error => {
                this.setState({ loadingDialog: false, openSnack: false, loadingManual: false });
                return Promise.reject(error);
            });
    }

    getLocations = () => {
        return BaseStore.httpClient.get('/oms/api/list/locations');
    }

    handleConfirmManualShip = () => {
        const { manualTrackingNumber, manualCarrier, shipments } = this.state;
        const newShipment = {
            trackingNumber: manualTrackingNumber,
            isManual: true,
            packageNumber: null,
            carrier: null,
            carrierName: manualCarrier,
            service: null,
            serviceName: null,
            dimensions: null,
            weight: null,
        }
        this.setState({
            loadingManual: true,
            shipments: [...shipments, newShipment],
        }, () => {
            this.handleConfirmUpdateShipments(false)
                .then(() => {
                    this.setState({
                        loadingManual: false,
                        openManualDialog: false,
                        manualCarrier: null,
                        manualTrackingNumber: null
                    });
                });
        });
    }

    getOrderInformation = () => {
        const orderInformation = BaseStore.httpClient.get(`/api/orders/${this.state.orderId}`);
        return orderInformation;
    }

    getCarriers = () => {
        return BaseStore.httpClient.get(`/oms/api/list/carriers`);
    }

    handleCarriersOnChange = (value, event) => {
        this.setState({ carrier: value, service: null, services: [] });
    }

    handleInputChange = (event) => {
        const target = event.target;
        const name = target.name;
        const value = target.value;

        const number = value.replace(/[^.0-9]/g, '');
        if (number < 1000) {
            this.setState({
                [name]: number,
                service: null,
                services: [],
            });
        }
    };

    handleInputNumberChange = (event) => {
        const target = event.target;
        const name = target.name;
        const value = target.value;

        const number = value.replace(/[^.0-9]/g, '');
        this.setState({
            [name]: number,
            service: null,
            services: [],
        });
    };

    handleInputTextChange = (event) => {
        const target = event.target;
        const name = target.name;
        const value = target.value;

        this.setState({
            [name]: value,
        });
    };

    handleServicesOnChange = (value, event) => {
        this.setState({ service: value });
    }

    getRates = () => {
        const { carrier, packageLength, packageWidth, packageHeight, packageWeight, order } = this.state;
        const { shipmentNumber } = order.transportAttrs;
        const body = {
            carrier: carrier?.code,
            weight: parseFloat(packageWeight),
            length: parseFloat(packageLength),
            width: parseFloat(packageWidth),
            height: parseFloat(packageHeight),
        };

        if (shipmentNumber) {
            this.setState({ loadingRates: true }, () => {
                return BaseStore.httpClient.post(`/oms/api/shipment/rates/${shipmentNumber}`, body)
                    .then(result => {
                        this.setState({ rates: result.data, openRateDialog: true, services: result.data, loadingRates: false });
                    })
                    .catch(error => {
                        this.setState({
                            loadingRates: false,
                        });
                        return error;
                    });
            });
        }
    }

    handleNewShipment = async (data) => {
        const { carrier, packageLength, packageWidth, packageHeight, packageWeight, service } = this.state;
        const newShipment = {
            trackingNumber: data.trackingNumber,
            isManual: false,
            packageNumber: data.packageNumber,
            carrier: carrier.code,
            carrierName: carrier.name,
            service: service.serviceCode,
            serviceName: service.serviceName,
            dimensions: {
                width: parseFloat(packageWidth),
                length: parseFloat(packageLength),
                height: parseFloat(packageHeight),
            },
            weight: {
                value: parseFloat(packageWeight),
                units: 'lbs',
            }
        }

        return newShipment;
    }

    updateShipments = () => {
        this.setState({
            packageLength: '',
            packageWidth: '',
            packageHeight: '',
            packageWeight: '',
            carrier: null,
            service: null,
            services: [],
        });
        this.handleConfirmUpdateShipments(false);
    }

    createShippingLabel = () => {
        const { carrier, packageLength, packageWidth, packageHeight, packageWeight, order, service, shipments } = this.state;
        const { shipmentNumber } = order.transportAttrs;
        const body = {
            carrier: carrier.code,
            service: service.serviceCode,
            weight: parseFloat(packageWeight),
            length: parseFloat(packageLength),
            width: parseFloat(packageWidth),
            height: parseFloat(packageHeight),
        };
        if (body.carrier && body.service) {
            this.setState({ loadingShippingLabel: true }, () => {
                return BaseStore.httpClient.post(`/oms/api/shipment/createLabel/${shipmentNumber}`, body)
                    .then(result => {
                        const { data } = result;
                        if (data) {
                            this.handleNewShipment(data)
                                .then(newShipment => {
                                    this.setState({ shipments: [...shipments, newShipment], loadingShippingLabel: false }, () => {
                                        convertToPdf(data.labelData, 'Shipping Label');
                                        this.updateShipments();
                                    });
                                });
                        }
                    })
                    .catch(error => {
                        this.setState({
                            loadingShippingLabel: false,
                        });
                        return error;
                    });
            });
        }

    }

    reprintShippingLabel = packageNumber => {
        if (packageNumber) {
            return BaseStore.httpClient.get(`/oms/api/print/shippingLabel/${packageNumber}`)
                .then(result => {
                    convertToPdf(result.data, 'Shipping Label');
                })
                .catch(error => console.log('error', error));
        }
    }

    handleVoidShipment = (selectedShipment, selectedShipmentIndex) => {
        const { shipments } = this.state;
        this.setState({ loadingVoid: true }, () => {
            this.voidShipment(selectedShipment)
                .then(() => {
                    shipments.splice(selectedShipmentIndex, true);
                    this.setState({ shipments }, () => {
                        this.handleConfirmUpdateShipments(false)
                            .then(() => {
                                this.setState({
                                    loadingVoid: false,
                                    openDeleteShipDialog: false,
                                    selectedShipment: null,
                                    selectedShipmentIndex: null,
                                });
                            });
                    });
                })
                .catch(() => {
                    this.setState({
                        loadingVoid: false,
                    });
                });
        });
    }

    voidShipment = selectedShipment => {
        const { order } = this.state;
        const { packageNumber } = selectedShipment;
        if (packageNumber) {
            return BaseStore.httpClient.post(`/oms/api/shipment/voidLabel/${order.orderNumber}`, { packageNumber });
        }
        return Promise.resolve();
    }

    checkRequired = () => {
        const { carrier, packageLength, packageWidth, packageHeight, packageWeight } = this.state;
        return !(carrier &&
            parseInt(packageLength) &&
            parseInt(packageWidth) &&
            parseInt(packageHeight) &&
            parseInt(packageWeight));
    }

    renderOrder = () => {
        let itemsList = [];
        const {
            loading,
            order,
            carrier,
            carriers,
            service,
            services,
            loadingRates,
            loadingShippingLabel,
            shipments,
            packageLength,
            packageWidth,
            packageHeight,
            packageWeight
        } = this.state;

        if (loading || order === null) {
            return (
                <CustomLoadingCard />
            );
        }

        const { orderLineItems } = order;
        itemsList.push(<OrderPaper key={order.id} staged order={order} handleOrderClick={() => {}} />);
        if (order.orderType === enumOrderStatus.bopis) {
            orderLineItems.forEach(orderLineItem => itemsList.push(<OrderPaperImage key={orderLineItem.id} orderLineItem={orderLineItem} />));
        } else {
            const shippingInformationCard = (
                <CustomCard key={'shippingInformationCard'} title='Shipping Information'>
                    <Grid container className='card-content'>
                        <Grid item xs={12} className='pb-20px'>
                            <Typography className='text-14px text-semibold pb-10px' variant="body2" color="black">
                                Package Dimensions and Weight
                            </Typography>
                            <div className='flex items-center justify-between'>
                                <div className='flex items-center gap-20px'>
                                    <CustomTextfield
                                        value={packageLength}
                                        name='packageLength'
                                        label='Length'
                                        sideLabel='inches'
                                        autoComplete='off'
                                        className='w-150px'
                                        size='small'
                                        onChange={(event) => this.handleInputChange(event)}
                                    />
                                    <CustomTextfield
                                        value={packageWidth}
                                        name='packageWidth'
                                        label='Width'
                                        sideLabel='inches'
                                        autoComplete='off'
                                        className='w-150px'
                                        size='small'
                                        onChange={(event) => this.handleInputChange(event)}
                                    />
                                    <CustomTextfield
                                        value={packageHeight}
                                        name='packageHeight'
                                        label='Height'
                                        sideLabel='inches'
                                        autoComplete='off'
                                        className='w-150px'
                                        size='small'
                                        onChange={(event) => this.handleInputChange(event)}
                                    />
                                </div>
                                <div>
                                    <CustomTextfield
                                        value={packageWeight}
                                        name='packageWeight'
                                        label='Weight'
                                        sideLabel='lbs'
                                        autoComplete='off'
                                        className='w-150px'
                                        size='small'
                                        onChange={(event) => this.handleInputChange(event)}
                                    />
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} className='flex gap-20px pb-20px'>
                            <CustomSelect
                                value={carrier}
                                label='Select Carrier'
                                options={carriers}
                                className='w-300px'
                                disabled={(carriers.length === 0)}
                                onChange={(event, value) => this.handleCarriersOnChange(value, event)}
                            />
                            <CustomButton
                                variant='contained'
                                color='primary'
                                onClick={() => this.getRates()}
                                disabled={this.checkRequired() || loadingRates}
                            >
                                Get Rates
                                {loadingRates && <CircularProgress sx={{ ml: 2 }} size={20} />}
                            </CustomButton>
                        </Grid>
                        {services.length > 0 && (
                            <Grid item xs={12} className='pb-20px'>
                                <CustomSelect
                                    value={service}
                                    label='Select Service Type'
                                    options={services}
                                    className='w-300px'
                                    disabled={(services.length === 0)}
                                    onChange={(event, value) => this.handleServicesOnChange(value, event)}
                                />
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <CardContent className='flex items-center gap-20px p-0'>
                                <CustomButton
                                    variant='contained'
                                    color='primary'
                                    onClick={() => this.createShippingLabel()}
                                    disabled={service == null || loadingShippingLabel}
                                >
                                    Create Shipping Label
                                    {loadingShippingLabel && <CircularProgress sx={{ ml: 2 }} size={20} />}
                                </CustomButton>
                                <Link className='text-14px text-semibold pointer-cursor' underline="hover" onClick={() => this.setState({ openManualDialog: true })}>
                                    Or Manually Enter Tracking Number
                                </Link>
                            </CardContent>
                        </Grid>
                    </Grid>
                </CustomCard>
            );
            itemsList.push(shippingInformationCard);

            if (shipments?.length > 0) {
                const trackingNumberCard = (
                    <CustomCard key={'trackingNumberCard'} title='Tracking Number'>
                        {shipments.map((shipment, index) => {
                            return (
                                <Grid key={index} container className='card-content border-bottom'>
                                    <Grid item xs={6}>
                                        <div className='flex flex-column gap-10px'>
                                            {!shipment.isManual && (
                                                <Typography className='text-14px' variant="body2" color="black">
                                                    Package #{shipment.packageNumber}
                                                </Typography>
                                            )}
                                            <Typography className='text-14px' variant="body2" color="black">
                                                Tracking Number:
                                                <Link underline="none"> {shipment.trackingNumber}</Link>
                                            </Typography>
                                            {!shipment.isManual && (
                                                <div>
                                                    <CustomButton
                                                        variant='contained'
                                                        color='primary'
                                                        onClick={() => this.reprintShippingLabel(shipment.packageNumber)}
                                                    >
                                                        Reprint Shipping Label
                                                    </CustomButton>
                                                </div>
                                            )}
                                        </div>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <div className='flex flex-column gap-10px'>
                                            {!shipment.isManual ? (
                                                <Fragment>
                                                    <Typography className='text-14px' variant="body2" color="black">
                                                        Carrier and Service Type: {shipment.carrierName} / {shipment.serviceName}
                                                    </Typography>
                                                    <Typography className='text-14px' variant="body2" color="black">
                                                        L x W x H (in inches): {shipment.dimensions?.length} x {shipment.dimensions?.width} x {shipment.dimensions?.height}
                                                    </Typography>
                                                    <Typography className='text-14px' variant="body2" color="black">
                                                        Weight (in lbs): {shipment.weight?.value}
                                                    </Typography>
                                                </Fragment>
                                            ) : (
                                                <Fragment>
                                                    <Typography className='text-14px' variant="body2" color="black">
                                                        Carrier and Service Type: {shipment.carrierName}
                                                    </Typography>
                                                    <Typography className='text-14px' variant="body2" color="black">
                                                        L x W x H (in inches): N/A
                                                    </Typography>
                                                    <Typography className='text-14px' variant="body2" color="black">
                                                        Weight (in lbs): N/A
                                                    </Typography>
                                                </Fragment>
                                            )}
                                        </div>
                                    </Grid>
                                    <Grid className='flex items-center p-20px' item xs={1}>
                                        <CloseIcon onClick={() => this.setState({ selectedShipment: shipment, selectedShipmentIndex: index, openDeleteShipDialog: true })} className='text-gray pointer-cursor' fontSize="large" />
                                    </Grid>
                                </Grid>
                            );
                        })}
                    </CustomCard>
                );

                itemsList.push(trackingNumberCard);
            }
        }

        return itemsList;
    }

    renderReleaseConfirmation = () => {
        let description;
        const { loading, order, openReleaseDialog, loadingDialog } = this.state;

        if (!loading && order !== null) {
            const { customerInfo } = order;

            description = (
                <React.Fragment>
                    <Typography className='text-capitalize text-16px text-semibold' olor="primary" gutterBottom>
                        {customerInfo.name}
                    </Typography>
                    <Typography className='text-14px' gutterBottom>
                        Address: {customerInfo.streetAddress && `${customerInfo.streetAddress}, `} {customerInfo.city && `${customerInfo.city}, `} {customerInfo.state}
                    </Typography>
                    <Typography className='text-14px' gutterBottom>
                        Phone: {formatPhoneNumber(customerInfo.phone)}
                    </Typography>
                </React.Fragment>
            );
        }

        return (
            <CustomDialog
                title='Confirm Customer'
                description={description}
                openDialog={openReleaseDialog}
                loadingDialog={loadingDialog}
                onClose={() => this.handleClose()}
                cancelButton={() => this.handleClose()}
                confirmButton={() => this.handleConfirmRelease()}
                cancelLabel='Close'
                confirmLabel='Confirm'
                small
                height={100}
            />
        );
    }

    renderCompleteShipConfirmation = () => {
        let description;
        const { loading, order, openCompleteShipDialog, loadingDialog } = this.state;

        if (!loading && order !== null) {

            description = (
                <React.Fragment>
                    <List className='list'>
                        <ListItem disablePadding>
                            <ListItemText className='text-14px'>
                                Confirm the following prior to shipping:
                            </ListItemText>
                        </ListItem>
                        <ListItem disablePadding>
                            <span>1.</span>
                            <ListItemText primary="Place all items, the packing list and promotional materials in the package." />
                        </ListItem>
                        <ListItem disablePadding>
                            <span>2.</span>
                            <ListItemText primary="Print all the shipping labels and attach it to the package(s)." />
                        </ListItem>
                        <ListItem disablePadding>
                            <span>3.</span>
                            <ListItemText primary="Seal the package so that it is ready to ship." />
                        </ListItem>
                    </List>
                </React.Fragment>
            );
        }

        return (
            <CustomDialog
                title='Complete Shipment'
                description={description}
                openDialog={openCompleteShipDialog}
                loadingDialog={loadingDialog}
                onClose={() => this.handleCompleteShipClose()}
                cancelButton={() => this.handleCompleteShipClose()}
                confirmButton={() => this.handleConfirmUpdateShipments(true)}
                cancelLabel='Cancel'
                confirmLabel='Confirmed'
                small
                height={180}
            />
        );
    }

    renderDeleteConfirmation = () => {
        const { selectedShipment, selectedShipmentIndex, openDeleteShipDialog, loadingVoid } = this.state;

        return (
            <CustomDialog
                title='Void Label Confirmation'
                description={`Are you sure you want to void the label and tracking number for this shipment?`}
                openDialog={openDeleteShipDialog}
                onClose={() => this.handleDeleteShipClose()}
                cancelButton={() => this.handleDeleteShipClose()}
                confirmButton={() => this.handleVoidShipment(selectedShipment, selectedShipmentIndex)}
                cancelLabel='Cancel'
                confirmLabel='Confirm'
                disabledConfirm={loadingVoid}
                disabledLoadingConfirm={loadingVoid}
                height={100}
            />
        );
    }

    renderManualShipment = () => {
        const { manualTrackingNumber, manualCarrier, openManualDialog, loadingManual } = this.state;

        let description = (
            <div className='flex flex-column gap-20px py-10px'>
                <CustomTextfield
                    value={manualTrackingNumber}
                    name='manualTrackingNumber'
                    label='Tracking Number'
                    autoComplete='off'
                    className='w-300px'
                    size='small'
                    onChange={(event) => this.handleInputTextChange(event)}
                />
                <CustomTextfield
                    value={manualCarrier}
                    name='manualCarrier'
                    label='Carrier'
                    autoComplete='off'
                    className='w-300px'
                    size='small'
                    onChange={(event) => this.handleInputTextChange(event)}
                />
            </div>
        );

        return (
            <CustomDialog
                title='Manual Shipment'
                description={description}
                openDialog={openManualDialog}
                onClose={() => this.handleManualClose()}
                cancelButton={() => this.handleManualClose()}
                confirmButton={() => this.handleConfirmManualShip()}
                cancelLabel='Cancel'
                confirmLabel='Apply'
                disabledConfirm={!(manualTrackingNumber && manualCarrier && !loadingManual) || (manualTrackingNumber && manualCarrier && loadingManual)}
                disabledLoadingConfirm={loadingManual}
                height={130}
            />
        );
    }

    renderRates = () => {
        let description;
        const { rates, openRateDialog } = this.state;

        if (rates.length > 0) {

            description = (
                <React.Fragment>
                    {
                        rates.map(rate => {
                            return (
                                <div className='rate-item'>
                                    <Typography className='text-14px' key={rate.serviceName} gutterBottom>
                                        {rate.serviceName} - ${rate.shipmentCost}
                                    </Typography>
                                </div>
                            );
                        })
                    }
                </React.Fragment>
            );
        } else {
            description = (
                <Typography className='text-14px' utterBottom>
                    No rates to show
                </Typography>
            );
        }

        return (
            <CustomDialog
                title='List of rates'
                description={description}
                openDialog={openRateDialog}
                onClose={() => this.handleRateClose()}
                confirmButton={() => this.handleRateClose()}
                confirmLabel='Close'
                height={600}
                {...this.state}
            />
        );
    }

    renderSuccessMessage = () => {
        let message;

        message = (
            <div className='flex items-center'>
                <CheckCircleIcon className='text-green' fontSize='small' />
                <span style={{ marginLeft: '8px' }}>Order successfully released</span>
            </div>
        );

        return (
            <CustomToast
                message={message}
                open={this.state.openSnack}
                onClose={() => this.setState({ openSnack: false })}
            />
        );
    }

    renderFooter = () => {
        const { order, shipments } = this.state;
        return (
            <CustomFooter>
                <CustomButton
                    onClick={() => this.props.history.push(`/orders`)}
                >
                    Close
                </CustomButton>
                <CustomButton
                    variant='contained'
                    color='primary'
                    onClick={(order.orderType === enumOrderStatus.bopis ? this.handleReleaseShipment : this.handleCompleteShipment)}
                    disabled={((order.orderType === enumOrderStatus.ship && shipments?.length > 0) || order.orderType === enumOrderStatus.bopis) ? false : true}
                >
                    {(order.orderType === enumOrderStatus.bopis ? 'Release Order' : 'Complete Shipment')}
                </CustomButton>
            </CustomFooter>
        );
    }

    UNSAFE_componentWillMount() {
        this.getOrderInformation()
            .then(result => {
                const shipments = result.data?.shipments ? result.data.shipments : [];
                return this.setState({
                    shipments,
                    order: result.data,
                });
            })
            .then(() => {
                if (this.state.order.orderType !== enumOrderStatus.bopis) {
                    return this.getCarriers()
                        .then(result => {
                            this.setState({ carriers: result.data, loading: false });
                        })
                        .catch(error => {
                            console.log('error', error)
                        });
                } else {
                    this.setState({ carriers: [], loading: false });
                }
            });
    }

    render() {
        const { order, loading } = this.state;

        return (
            <React.Fragment>
                <AppBar
                    position="static"
                    color="default"
                    elevation={0}
                    sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
                >
                    <Container style={{ maxWidth: '1000px' }}>
                        <Toolbar sx={{ flexWrap: 'wrap' }}>
                            <div className='back pointer-cursor text-16px text-semibold' onClick={() => this.props.history.push(`/orders`)}>
                                <IconButton className='back-button' size="small">
                                    <ChevronLeftIcon />
                                </IconButton>
                                <Typography className='text-16px text-semibold' color="inherit" noWrap sx={{ flexGrow: 1, marginRight: '5px' }}>
                                    {order !== null ? order.orderType === enumOrderStatus.bopis ? 'Release Order' : 'Ship' : ''}
                                </Typography>
                                {order === null ? <CircularProgress size={15} /> : order.transportAttrs?.orderNumber}
                            </div>
                        </Toolbar>

                    </Container>
                </AppBar>
                <Container disableGutters maxWidth="lg" component="main" sx={{ pt: 6, pb: 6 }} style={{ maxWidth: '900px' }}>

                    <Grid container spacing={2} sx={{ pb: 6 }}>
                        {this.renderOrder()}
                    </Grid>

                </Container>
                {this.renderReleaseConfirmation()}
                {this.renderCompleteShipConfirmation()}
                {this.renderDeleteConfirmation()}
                {this.renderManualShipment()}
                {this.renderRates()}
                {this.renderSuccessMessage()}
                {order && !loading && this.renderFooter()}
            </React.Fragment >
        );
    }
}

export default withRouter(withStyles(styles)(Staged));
