import { enumOrderStatus } from './';

const orderFilter = {
    'toPick': [
        enumOrderStatus.pickRequired,
        enumOrderStatus.inProgress
    ],
    'staged': [
        enumOrderStatus.staged,
    ],
    'all': [
        enumOrderStatus.pickRequired,
        enumOrderStatus.inProgress,
        enumOrderStatus.staged,
        enumOrderStatus.released,
        enumOrderStatus.canceled,
    ],
    'defaultAcceptedStatus': [
        enumOrderStatus.pickRequired,
        enumOrderStatus.inProgress,
        enumOrderStatus.staged,
    ],
    'stagedAcceptedStatus': [
        enumOrderStatus.staged,
        enumOrderStatus.toRelease
    ],
    'releasedAcceptedStatus': [
        enumOrderStatus.released,
        enumOrderStatus.canceled
    ],
    'excempted': [
        enumOrderStatus.hidden,
    ],
}

export default orderFilter;