import React from 'react';
import PropTypes from 'prop-types';

import { styled } from "@mui/styles";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

const AutocompleteStyled = styled(Autocomplete)({
    fontSize: '12px !important',
});

const CustomSelect = ({ value, label, options, ...props }) => {
    return (
        <AutocompleteStyled
            required
            size="small"
            value={value}
            options={options}
            renderInput={(params) => <TextField {...params} label={label} />}
            getOptionLabel={option => option.value || option.text || option.name || option.serviceName + ` $${option.shipmentCost}`}
            {...props}
        />
    )
}

CustomSelect.propTypes = {
    exception: PropTypes.array,
}

CustomSelect.defaultProps = {
    exception: [],
}

export default CustomSelect;
